<template>
  <div>
    <x-table
      :no-data-text="CA('breedArea_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @on-expand="handleExpand"
      @search="search"
      @page-change="pageChange"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="养殖区名称" prop="name">
          <Input clearable v-model="form.name"></Input>
        </FormItem>
        <FormItem label="所属养殖场" prop="farm">
          <Select v-model="form.farm">
            <Option
              v-for="(item, index) in farmList"
              :key="index"
              :value="item.id + '/' + item.siteType"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="养殖区面积" prop="area">
          <Input v-model="form.area">
            <span slot="append">㎡</span>
          </Input>
        </FormItem>
        <FormItem label="养殖区绘制" prop="mapPosition">
          <Input
            :placeholder="
              form.farm ? '点击右侧绘制生产区地图' : '请先选择养殖场'
            "
            v-model="form.mapPosition"
            disabled
          >
            <span
              slot="append"
              class="iconfont"
              :style="{ cursor: form.farm ? 'pointer' : 'not-allowed' }"
              @click="drawMap"
              >&#xe648;</span
            >
          </Input>
        </FormItem>
        <FormItem label="备注">
          <Input
            type="textarea"
            :rows="3"
            clearable
            v-model="form.remark"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <draw-map-modal
      v-model="drawMapModalShow"
      @draw-finish="drawFinish"
      :mapPosition="form.mapPosition"
    ></draw-map-modal>
  </div>
</template>

<script>
import drawMapModal from "./drawMapModal";
import CU from "@/common/util";
import livestockOnHand from "./livestockOnHand";
export default {
  name: "",
  components: {
    drawMapModal,
    livestockOnHand,
  },
  data() {
    return {
      table: {
        columns: [
          {
            type: "expand",
            width: 60,
            render: (h, { row }) => {
              return (
                <livestockOnHand
                  props={{ row }}
                  onAdd-finish={this.addFinish}
                ></livestockOnHand>
              );
            },
          },
          {
            title: "养殖区名称",
            minWidth: 110,
            key: "name",
          },
          {
            title: "所属养殖场",
            minWidth: 110,
            key: "farmName",
          },
          {
            title: "养殖区面积",
            minWidth: 110,
            render: (h, { row }) => {
              return <span>{row.area + "㎡"}</span>;
            },
          },
          {
            title: "存栏量",
            minWidth: 100,
            key: "count",
          },
          {
            title: "备注",
            minWidth: 100,
            key: "remark",
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("breedArea_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("breedArea_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        name: "",
        area: "",
        remark: "",
        mapPosition: "",
        thirdLongLat: "",
        farm: "",
      },
      rules: {
        name: [{ required: true, message: "请填写养殖区名称" }],
        area: [
          { required: true, message: "请填写养殖区面积" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的面积",
          },
        ],
        farm: [{ required: true, message: "请选择养殖场" }],
        mapPosition: [{ required: true, message: "请勾画养殖区" }],
      },
      farmList: [],
      drawMapModalShow: false,
      rowIds: new Set(),
      search_data: {},
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增养殖区",
            ca: "breedArea_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "养殖区名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "养殖场",
              component: "select",
              field: "farmId",
              defaultValue: "",
              data: this.farmList,
              parameterField: "id",
              showField: "name",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    add() {
      this.modal = {
        show: true,
        title: "新增养殖区",
        submitLoading: false,
      };
    },
    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    addFinish() {
      this.getList();
    },
    handleExpand(row, status) {
      if (status) {
        this.rowIds.add(row.id);
      } else {
        this.rowIds.delete(row.id);
      }
    },
    getFarm() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        siteType: "2",
      }).then((res) => {
        this.farmList = res.list;
      });
    },
    drawMap() {
      if (!this.form.farm) return;
      if (!this.form.mapPosition) {
        let farm = this.farmList.filter(
          (item) => item.id == this.form.farm.split("/")[0]
        )[0];
        let mapPosition = JSON.parse(farm.mapPosition);
        mapPosition.features[0].properties.editArea = false;
        farm.mapPosition = mapPosition;
        if (this.table.data.length > 0) {
          let scene = this.table.data
            .filter((el) => el.farmId == this.form.farm.split("/")[0])
            .map((item) => JSON.parse(item.mapPosition));
          let features = scene.map((item) => {
            item.features.forEach((el) => {
              el.properties.editArea = false;
            });
            return item;
          });
          let m = [];
          features.forEach((item) => {
            m = m.concat(item.features);
          });
          let n = farm.mapPosition;
          n.features.push(...m);
          farm.mapPosition = n;
        }
        this.form.mapPosition = JSON.stringify(farm.mapPosition);
      }
      this.drawMapModalShow = true;
    },
    drawFinish(data) {
      this.form.mapPosition = JSON.stringify(data);
      this.form.thirdLongLat = data.features[0].properties.center;
      this.drawMapModalShow = false;
    },

    getList() {
      if (!this.CA("breedArea_check")) return;
      this.table.loading = true;
      this.$post(this.$api.PRODUCTION_AREA.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: "2",
        ...this.search_data,
      })
        .then((res) => {
          res.list.forEach((item) => {
            if (this.rowIds.has(item.id)) {
              item._expanded = true;
            }
          });
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.farm = row.farmId + "/" + row.siteType;
      let mapPosition = JSON.parse(
        this.farmList.filter((item) => item.id == row.farmId)[0].mapPosition
      );
      mapPosition.features[0].properties.editArea = false;
      let data = this.table.data.filter(
        (item) => item.id != row.id && item.farmId == row.farmId
      );
      if (data.length > 0) {
        let scene = data.map((item) => JSON.parse(item.mapPosition));
        let features = scene.map((item) => {
          item.features.forEach((el) => {
            el.properties.editArea = false;
          });
          return item;
        });
        let m = [];
        features.forEach((item) => {
          m = m.concat(item.features);
        });
        mapPosition.features.push(...m);
      }
      let features = JSON.parse(row.mapPosition).features.map((item) => {
        item.properties.editArea = true;
        return item;
      });
      mapPosition.features.push(...features);
      this.form.mapPosition = JSON.stringify(mapPosition);
      this.modal = {
        show: true,
        title: "编辑养殖区",
        submitLoading: false,
      };
    },

    del(id) {
      this.$post(this.$api.PRODUCTION_AREA.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        area: "",
        remark: "",
        mapPosition: "",
        thirdLongLat: "",
        farm: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      let n = JSON.parse(this.form.mapPosition);
      let features = n.features.filter(
        (item) => item.properties.editArea != false
      );
      if (features.length == 0) {
        this.form.mapPosition = "";
      } else {
        n.features = features;
        this.form.mapPosition = JSON.stringify(n);
      }
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.farmId = this.form.farm.split("/")[0];
        params.siteType = this.form.farm.split("/")[1];
        this.modal.submitLoading = true;
        this.$post(
          params.id
            ? this.$api.PRODUCTION_AREA.UPDATE
            : this.$api.PRODUCTION_AREA.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getFarm();
    this.getList();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
/deep/ td.ivu-table-expanded-cell {
  background: #efefef;
}
</style>