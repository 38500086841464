<template>
  <div class="livestock-on-hand">
    <div class="add">
      <Button
        v-if="CA('loh_add')"
        type="primary"
        icon="ios-add"
        size="small"
        @click="add"
        >存栏上报</Button
      >
    </div>
    <Table
      :on-data-text="CA('loh_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      size="small"
    ></Table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="养殖品种" prop="categoryId">
          <Select v-model="form.categoryId">
            <Option v-for="item in category" :value="item.id" :key="item.id">{{item.name}}</Option>
          </Select>
        </FormItem>
        <FormItem label="变更类型" prop="type">
          <Select v-model="form.type">
            <Option value="1">增</Option>
            <Option value="2">减</Option>
          </Select>
        </FormItem>
        <FormItem label="数量" prop="num">
          <Input v-model="form.num"></Input>
        </FormItem>
        <FormItem label="描述" prop="describes">
          <Input v-model="form.describes" type="textarea" :rows="3"></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import CU from "@/common/util";
export default {
  name: "",
  props: {
    row: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "养殖品种",
            minWidth: 100,
            key: "categoryName",
          },
          {
            title: "存栏量",
            minWidth: 100,
            key: "num",
          },
          {
            title: "操作",
            width: 150,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("loh_edit") && (
                    <a style="margin-right:10px" onClick={() => this.edit(row)}>
                      编辑
                    </a>
                  )}
                  {this.CA("loh_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        farmId: "",
        sceneId: "",
        categoryId: '',
        num: "",
        siteType: "2",
        type: '',
        describes: ''
      },
      rules: {
        categoryId: [
          { required: true, message: "请选择养殖品种" },
        ],
        num: [
          { required: true, message: "请填写存栏量" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的存栏量",
          },
        ],
        type: [{required: true, message: '请选择变更类型'}]
      },
      category: [],
    };
  },
  methods: {
    add() {
      this.modal = {
        show: true,
        title: "新增存栏",
        submitLoading: false,
      };
    },
    getList() {
      if (!this.CA("loh_check")) return;
      this.table.loading = true;
      this.$post(this.$api.LIVESTOCK_ON_HAND.LIST, {
        sceneId: this.row.id,
        siteType: "2",
      })
        .then((res) => {
          this.table.data = res.list;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.categoryId = row.categoryId
      this.modal = {
        show: true,
        title: "编辑存栏",
        submitLoading: false,
      };
    },
    del(id) {
      this.$post(this.$api.LIVESTOCK_ON_HAND.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
        this.$emit("add-finish");
      });
    },
    getCategory() {
      this.$post(this.$api.USER_PRODUCT.LIST).then((res) => {
        this.category = res.list
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        farmId: "",
        sceneId: "",
        categoryId: '',
        num: "",
        siteType: "2",
        type: '',
        describes: ''
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.farmId = this.row.farmId;
        params.sceneId = this.row.id;
        this.modal.submitLoading = true;
        this.$post(
          params.id
            ? this.$api.LIVESTOCK_ON_HAND.EDIT
            : this.$api.LIVESTOCK_ON_HAND.ADD_RECORD,
          params
        )
          .then(() => {
            this.getList();
            this.$emit("add-finish");
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
    this.getCategory();
  },
  watch: {
    row(n) {
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.livestock-on-hand {
  width: 100%;
  height: 100%;
  .add {
    text-align: right;
    padding-bottom: 16px;
  }
}
/deep/ .ivu-table-wrapper {
  overflow: hidden;
}
</style>